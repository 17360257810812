import { NextPage } from 'next';
import { SeoMetaTagType } from 'react-datocms';

import { renderModularContent } from 'components/cms/utils/modular-content';
import { Meta } from 'components/common/meta';
import { getAllPostsForHome } from 'lib/api';

import { FooterType } from '../components/common/footer';
import { HeaderType } from '../components/common/header';
import { Layout } from '../components/common/layout';
import { GqlHomePageModelContentField, GqlHomePageRecord } from '../types';

export type HomePageType = {
  homePage: GqlHomePageRecord & {
    pageSEO: SeoMetaTagType[];
  };
  globalSEO: {
    favicon: SeoMetaTagType[];
  };
  header: HeaderType;
  footer: FooterType;
};

const Index: NextPage<HomePageType> = ({
  homePage,
  globalSEO,
  header,
  footer,
}) => {
  if (!homePage) return null;

  return (
    <>
      <Meta
        globalSEO={globalSEO}
        metadataSchema={homePage.metadataSchema}
        pageSEO={homePage?.pageSEO}
      />
      <Layout footer={footer} hasPadding={false} header={header}>
        <div className="pt-20 bg-grey-20 dark:bg-blue-900">
          {homePage.content &&
            renderModularContent(
              homePage.content as Array<GqlHomePageModelContentField>,
            )}
        </div>
      </Layout>
    </>
  );
};

export default Index;

export async function getStaticProps({ preview = false }) {
  const data = await getAllPostsForHome(preview);

  return {
    props: {
      preview,
      homePage: data?.homePage || {},
      globalSEO: data?.globalSEO || {},
    },
  };
}
